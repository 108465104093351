import React from 'react'
import {
  Breadcrumbs,
  Hero,
  NotFound,
  Page,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'Stranica nije pronađena | Protupožarna zaštita',
  description: '',
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    meta={meta}
    locale={pageContext.locale}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: '/404', name: 'error404.meta.title'},
      ]}
    />
    <Hero
      title="error404.title"
      subtitle="error404.subtitle"
      description=""
    />
    <NotFound locale={pageContext.locale} />
  </Page>
)

export default Homepage